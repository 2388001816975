import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typing, clearForm } from '../actions/deal';
import { loadAll as loadContacts, clearForm as clearContactForm } from '../actions/contact';
import { load } from '../actions/deal_builder';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  // Fab,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import { Add as AddIcon } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputTextDollar } from '../components/FormElements';
// import DealContactEditDialog from './DealContactEditDialog.js';
import AlertFormError from '../components/AlertFormError';
import { isObjectEmpty } from '../helpers/GeneralFunctions';
import InputAdornment from '@mui/material/InputAdornment';
// import { SuccessSnackbar2 } from '../components/Snackbars';
import ContactSelect from './DealEditDialog.ContactSelect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const types = [
  { val: '' },
  { val: 'Birthday' },
  { val: 'Corporate' },
  { val: 'Engagement' },
  { val: 'Wedding' },
  { val: 'Other' },
];

const DealEditDialog = ({
  deal,
  deal_form,
  open,
  openEditorModal,
  closeEditorModal,
  handleSave,
  fields,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState([]);
  const [isHidden, setIsHidden] = useState('visible'); // eslint-disable-line no-unused-vars
  const [formFields, setFormFields] = useState({});

  useEffect(() => {
    let form = [];
    if (open) {
      const data = Object.prototype.hasOwnProperty.call(deal, 'additional_info')
        ? JSON.parse(deal.additional_info)
        : {};
      if (fields) {
        form = fields;
      } else {
        form = JSON.parse(deal_form.deals_other_info_details);
      }
      data.archived = !!deal.archived
      setFormFields(data);
      const parseForm = [...form].map((item) => {
        let field = item.title.replace(/ /g, '_').toLowerCase();
        let value = null;

        if (data && data[field]) {
          value = data[field];
        }
        return {
          ...item,
          value,
          fieldName: field,
        };
      });
      setForm(parseForm);
    } else {
      setFormFields({});
      setForm([]);
    }
  }, [open]);

  const handleFormChange = (event) => {
    let value = event.target.value;

    if (event.target.type === 'checkbox' && event.target.name === 'archived') {
      value = event.target.checked;
    }

    if (event.target.type === 'checkbox' && event.target.name != 'archived') {
      let newOptions = deal[event.target.name] ?? [];
      if (event.target.checked) {
        newOptions.push(event.target.value);
      } else {
        newOptions = newOptions.filter((option) => option != event.target.value);
      }
      // console.log('options', newOptions);
      value = newOptions;
    }
    formFields[event.target.name] = value;
    setFormFields({ ...formFields });

    // props.typing({ [event.target.name]: value });
  };

  const handleContactChange = (contact_id) => {
    setFormFields({
      ...formFields,
      contact_id,
    });
  };

  const handleArchive = (event) => {
    formFields['archived'] = event.target.checked
    setFormFields({ ...formFields })

  }

  const handleFormSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    await handleSave({ ...formFields, id: deal.id, date: deal.date });
    setIsSubmitting(false);
  };

  const handleDateChange = (dateStr) => {
    let date = '';

    if (dateStr) {
      dateStr = new Date(dateStr);
      date = dateStr.getFullYear() + '-' + (dateStr.getMonth() + 1) + '-' + dateStr.getDate();
    }

    props.typing({ date: date });
  };
  if (!open) {
    return '';
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeEditorModal()}
        maxWidth="sm"
        fullWidth
      >
        <form
          autoComplete="off"
          onSubmit={handleFormSubmit}
          style={{ display: 'flex', flexDirection: 'column', maxHeight: '90vh' }}
        >
          <DialogTitle className="modal-header">
            {deal.id ? 'Update Deal Details' : 'Create New Deal'}
          </DialogTitle>
          <div style={{ maxHeight: '85vh', overflowY: 'auto' }}>
            <DialogContent className="pt-4">
              {!isObjectEmpty(props.errors) && <AlertFormError errors={props.errors} />}
              {/* custom form */}
              {(() => {
                if (deal_form.id) {
                  if (!form) {
                    return '';
                  }
                  return form.map((item, index) => (
                    <div key={'field-' + index}>
                      {(() => {
                        if (item.type == 'Date') {
                          return (
                            <Field
                              type={item.type}
                              title={item.title}
                              value={deal.date ? new Date(deal.date) : null}
                              required={item.required}
                              errors={props.errors}
                              deal={deal}
                              onChange={(event) => handleDateChange(event)}
                              onContactChange={(contactId) => handleContactChange(contactId)}
                              onSetHiddenContact={setIsHidden}
                            />
                          );
                        }

                        return (
                          <Field
                            type={item.type}
                            title={item.title}
                            name={item.fieldName}
                            value={formFields[item.fieldName]}
                            required={item.required}
                            options={item.options}
                            errors={props.errors}
                            deal={deal}
                            onChange={(event) =>
                              item.type == 'Dropdown' && item.title == 'Contact'
                                ? handleContactChange(event)
                                : handleFormChange(event)
                            }
                            onSetHiddenContact={setIsHidden}
                            contacts={props.contacts}
                            loadAll={props.loadAll}
                            loadAllContacts={props.loadContacts}
                            onClearContact={clearContactForm}
                          />
                        );
                      })()}
                    </div>
                  ));
                }

                return (
                  <>
                    {/* Default Form */}
                    <div className="mb-2">
                      {/*<label style={{fontSize:'12px'}}>Contact</label>*/}
                      <ContactSelect
                        label="Contact"
                        name="contact_id"
                        handleSelectChange={(contact_id) => handleContactChange(contact_id)}
                        setIsHidden={setIsHidden}
                        {...props}
                      />
                    </div>

                    {/*<InputLabel shrink> Title </InputLabel>*/}
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className="mt-0"
                      name="title"
                      size="small"
                      label="Title"
                      placeholder="Title"
                      defaultValue={deal.title}
                      onBlur={(event) => handleFormChange(event)}
                      error={
                        Object.prototype.hasOwnProperty.call(props.errors, 'title') ? true : false
                      }
                    />

                    <Grid container spacing={2}>
                      <Grid item sm={7} xs={12} mt={1}>
                        {/*<InputLabel shrink> Date </InputLabel>*/}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          className="date-picker-div"
                        >
                          <DatePicker
                            label="Date"
                            disableToolbar
                            autoOk
                            format="MM/dd/yyyy"
                            value={deal.date ? new Date(deal.date) : null}
                            onChange={handleDateChange}
                            fullWidth
                            animateYearScrolling={true}
                            inputVariant="outlined"
                            margin="dense"
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                variant="outlined"
                                margin="dense"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <InputTextDollar
                          label="Value (optional)"
                          placeholder="Value (optional)"
                          name="value"
                          size="small"
                          value={deal.value}
                          handleFormChange={(event) => handleFormChange(event)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item sm={7} xs={12}>
                        <TextField
                          size="small"
                          className="form-group"
                          style={{ width: '100%' }}
                          select
                          label="Type"
                          placeholder="Type"
                          name="type"
                          value={deal.type}
                          onChange={handleFormChange}
                        >
                          {types.map((option, index) => (
                            <MenuItem key={index} value={option.val}>
                              {option.val}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {deal.type === 'Other' && (
                        <Grid item sm={5} xs={12}>
                          {/*<InputLabel shrink> Specify </InputLabel>*/}
                          <TextField
                            size="small"
                            label="Specify"
                            placeholder="Specify"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={deal.type === 'Other' ? 'd-block' : 'd-none'}
                            name="type_other"
                            defaultValue={deal.type_other}
                            onBlur={(event) => handleFormChange(event)}
                          />
                        </Grid>
                      )}
                    </Grid>

                    {/*<label style={{fontSize:'12px'}}>Description</label>*/}
                    <TextField
                      multiline={true}
                      label="Description"
                      placeholder="Description"
                      style={{ width: '100%' }}
                      aria-label="minimum height"
                      minRows={3}
                      name="notes"
                      size="small"
                      defaultValue={deal.notes}
                      onBlur={(event) => handleFormChange(event)}
                    />
                  </>
                );
              })()}
              {deal.id && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formFields.archived ? true : false}
                          onChange={handleArchive}
                          name="archived"
                        />
                      }
                      label="Archived this deal"
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
          </div>
          <DialogActions>
            <Button onClick={() => closeEditorModal()} color="primary" className="mr-1 mb-2">
              Cancel
            </Button>
            {(() => {
              if (isSubmitting) {
                return (
                  <Button type="button" variant="contained" color="secondary" className="mr-3 mb-2">
                    Saving. . .
                  </Button>
                );
              }
              return (
                <Button type="submit" variant="contained" color="secondary" className="mr-3 mb-2">
                  Save
                </Button>
              );
            })()}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
DealEditDialog.propTypes = {
  deal: PropTypes.object,
  open: PropTypes.bool,
  openEditorModal: PropTypes.func,
  closeEditorModal: PropTypes.func,
  handleSave: PropTypes.func,
};
DealEditDialog.defaultProps = {
  open: false,
  openEditorModal: () => { },
  closeEditorModal: () => { },
  handleSave: () => { },
};

const Field = ({
  type,
  title,
  name,
  value,
  required,
  options,
  errors,
  deal,
  onChange,
  onSetHiddenContact,

  contacts,
  loadAll,
  loadAllContacts,
  onClearContact,
}) => {
  if (type == 'Short Answer') {
    return (
      <div>
        {title == 'Value' ? (
          <TextField
            fullWidth
            type="number"
            size="small"
            variant="outlined"
            className="form-group"
            name={name}
            defaultValue={value}
            label={title}
            placeholder={title}
            required={required}
            onBlur={(event) => onChange(event)}
            InputProps={{
              endAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
          // error={props.errors.hasOwnProperty('title') ? true : false}
          />
        ) : (
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            className="form-group"
            name={name}
            defaultValue={value}
            label={title}
            placeholder={title}
            required={required}
            onBlur={(event) => onChange(event)}
          // error={props.errors.hasOwnProperty('title') ? true : false}
          />
        )}
      </div>
    );
  }

  if (type == 'Date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} className="date-picker-div">
        <DatePicker
          label={title}
          disableToolbar
          autoOk
          format="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          animateYearScrolling={true}
          inputVariant="outlined"
          margin="dense"
          renderInput={(params) => (
            <TextField
              fullWidth
              size="small"
              {...params}
              variant="outlined"
              margin="dense"
              className="form-group"
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type == 'Multiple Choice') {
    return (
      <Grid item sm={5} xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={value}
            name="radio-buttons-group"
          >
            {options.map((option, key) => (
              <FormControlLabel
                value={option}
                key={key}
                control={<Radio />}
                label={option}
                onChange={(event) => onChange(event)}
                name={name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }

  if (type == 'Checkbox') {
    return (
      <Grid item sm={5} xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
          {options.map((option, key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  value={option}
                  checked={value}
                  key={key}
                  onChange={(event) => onChange(event)}
                  name={name}
                />
              }
              label={option}
            />
          ))}
        </FormControl>
      </Grid>
    );
  }

  if (type == 'Dropdown') {
    return (
      <>
        {title == 'Contact' ? (
          <div className="mb-2">
            <ContactSelect
              name={name}
              label="Contact"
              loadAll={loadAll}
              errors={errors}
              deal={deal}
              contacts={contacts}
              clearContactForm={onClearContact}
              handleSelectChange={(contactId) => onChange(contactId)}
              loadContacts={loadContacts}
              setIsHidden={onSetHiddenContact}
            />
          </div>
        ) : (
          <>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              className="form-group"
              select
              label={title}
              placeholder={title}
              name={title.replace(/ /g, '_').toLowerCase()}
              defaultValue={value}
              required={required}
              onChange={onChange}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {title == 'Type' && (
              <TextField
                size="small"
                label="Specify"
                placeholder="Specify"
                fullWidth
                variant="outlined"
                margin="dense"
                className={deal.type === 'Other' ? 'd-block mb-3' : 'd-none'}
                name="type_other"
                defaultValue={deal.type_other}
                onBlur={(event) => onChange(event)}
              />
            )}
          </>
        )}
      </>
    );
  }

  if (type == 'Paragraph') {
    return (
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        className="form-group"
        aria-label="minimum height"
        multiline={true}
        name={name}
        defaultValue={value}
        label={title}
        placeholder={title}
        minRows={3}
        required={required}
        onBlur={(event) => onChange(event)}
      />
    );
  }

  return <span></span>;
};
Field.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  options: PropTypes.array,
  errors: PropTypes.object,
  deal: PropTypes.object,
  onChange: PropTypes.func,
  onSetHiddenContact: PropTypes.func,
  contacts: PropTypes.array,
  loadAll: PropTypes.func,
  loadAllContacts: PropTypes.func,
  onClearContact: PropTypes.func,
};
Field.defaultProps = {
  required: false,
  options: [],
  errors: {},
  deal: {},
  onChange: () => { },
  onSetHiddenContact: () => { },
  contacts: [],
  loadAll: () => { },
  loadAllContacts: () => { },
  onClearContact: () => { },
};

const mapStateToProps = (state) => ({
  isProcessing: state.Deal.isProcessing,
  success: state.Deal.success,
  errors: state.Deal.errors,
  deal: state.Deal.deal,
  contacts: state.Contact.contacts,
  contact: state.Contact.contact,
  deal_form: state.DealBuilder.deal_form,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  typing: (payload) => typing(payload),
  clearForm: () => clearForm(),
  loadContacts: () => loadContacts(),
  clearContactForm: () => clearContactForm(),
  load: (payload) => load(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DealEditDialog);
