import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { connect } from 'react-redux';

/*
	*** headCells keys
	id -
	label -
	sortkey - default sorted by id
	align - default: left
	type - default: text
	sortable - default: true
	searchable - default: true
	restrict - default:all > (venue|god_admin)
	type - default: text > (text|date|number)
*/

const TableHeader = (props) => {
  const { user, headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (
            Object.prototype.hasOwnProperty.call(headCell, 'restrict') &&
            headCell.restrict !== user.role
          ) {
            return null;
          }

          return (
            <TableCell
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                minWidth: headCell.width || '',
              }}
              key={headCell.id}
              align={
                Object.prototype.hasOwnProperty.call(headCell, 'align') ? headCell.align : 'left'
              }
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: `${headCell.width}px` }}
            >
              {Object.prototype.hasOwnProperty.call(headCell, 'sortable') && !headCell.sortable ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.sortkey ? headCell.sortkey : headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
TableHeader.propTypes = {
  user: PropTypes.object,
  headCells: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
};
TableHeader.defaultProps = {
  user: {},
  order: '',
  orderBy: '',
  onRequestSort: () => {},
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

export default connect(mapStateToProps)(TableHeader);
